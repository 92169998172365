<template>
	<Modal width="800px">
		<template #modal-title>
			Authentication Type
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<p>
				A Cassie Link can be accessed using one of 5 Authentication types:
			</p>
			<p>
				<b>
					Direct
				</b> - The data subject will not need to be asked to provide any information when clicking on the link to submit preferences.  The information contained in the Link Identification Fields is sufficient proof of the Data Subjects identity
			</p>
			<p>
				<b>
					Confirm Value
				</b> - The Data Subject will be asked to enter the value held for them in the selected field in Cassie.  Access will only be granted if the value matches what is held in Cassie.
			</p>
			<p>
				<b>
					Provide Value
				</b> - The Data Subject will be asked to confirm their identity by entering a value for them in the selected field.  The data collected will be saved against the Data Subjects Personal Information Details
			</p>
			<p>
				<b>
					Prove Email
				</b> - The Data Subject will be asked to enter the email address held for them in Cassie and will receive an email containing a verification link.  Only when they click on the link contained in that email (proving that they own that email address) will access be granted
			</p>
			<p>
				<b>
					Provide & Prove Email
				</b> - The data subject will be asked to provide an Email address.  The Email address provided will receive an Email containing a verification link.  Only when they click on the link contained in that email (proving that they own that email address) will access be granted
			</p>
			<p v-if="isPreferencePage">
				This authentication method allows the Preference Page to perform as a 'sign-up' page when the Email address & given identifiers do not match any other record held in Cassie.
			</p>
			<p v-else>
				This authentication method allows the Link to perform as a 'sign-up' action when the Email address & given identifiers do not match any other record held in Cassie.
			</p>
		</template>
		<template #modal-footer>
			<v-spacer />
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
export default {
	components: {
		Modal,
		IconButton,
		SecondaryActionButton
	},
	props: {
		isPreferencePage: {
			type: Boolean,
			default: true
		}
	}
}
</script>
