<template>
	<!-- eslint-disable max-lines-->
	<validation-observer
		#default="{ reset }"
		tag="div"
		class="cassie-vertical-md"
	>
		<SectionCard>
			<template #title>
				Details
			</template>
			<template
				v-if="linkType !== 2"
				#title-action
			>
				<v-tooltip left>
					<template #activator="{ on, attrs }">
						<div
							v-bind="attrs"
							v-on="on"
						>
							<Toggle
								label-left
								label="Enabled"
								:value="isEnabled"
								@update:value="$emit('update:isEnabled', $event)"
							/>
						</div>
					</template>
					<span>
						Please be aware that disabling this link will redirect all access requests to your error page
					</span>
				</v-tooltip>
			</template>
			<template #body>
				<Message
					v-if="enableOneClickUnsubscribe"
					type="warning"
				>
					One Click Unsubscribe Action Links will not process consent if embedded in Email Content or Websites
				</Message>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value="linkName"
							:disabled="!userFullPermissions"
							:label="linkType === linkTypes.preferencePageLink ? 'Preference Page Name *' : `Link name *`"
							:rules="{required: true, max: 256}"
							@input="$emit('update:linkName', $event)"
						/>
					</v-col>
					<v-col
						v-if="linkType !== linkTypes.actionLink"
						cols="6"
					>
						<TextField
							:value="linkText"
							:disabled="!userFullPermissions"
							label="Link text *"
							:rules="{required: true, max: 500}"
							tooltip-text="The Link Text will be visible to the website viewer if this Link is embedded within an Email or Web Page"
							@input="$emit('update:linkText', $event)"
						/>
					</v-col>
					<v-col
						v-if="linkType === 1"
						cols="6"
						class="d-flex align-center"
					>
						<Toggle
							v-if="authTypeId == DIRECT_AUTHENTICATION_TYPE"
							:value="enableOneClickUnsubscribe"
							label="One Click Unsubscribe Link"
							tooltip-text="Enabling this functionality allows for the Action Link to be embedded within outbound Emails to support 'One Click Unsubscribe' functionality."
							@update:value="$emit('update:enableOneClickUnsubscribe', $event)"
						/>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:label="'Brand *' | useLabels"
							:value="brandId"
							:disabled="editMode || !clientMultiBranded || singleBrand || cloneMode"
							:items="(brandId !== 0 || userHasUniversalBrand) ? brandOptions : universalBrand"
							custom-sort
							:rules="{ required: clientMultiBranded}"
							@input="$emit('update:brandId', $event)"
						/>
					</v-col>
					<v-col cols="6">
						<Dropdown
							label="Default Language *"
							:value="customDefaultLanguageId"
							:disabled="!userFullPermissions"
							:items="assignedLanguages"
							rules="required"
							tooltip-text="Set the Default Language that the page will open in if a 'languagecode' parameter is not passed within the Link URL"
							@input="$emit('update:customDefaultLanguageId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="linkType !== linkTypes.publicPortalLink"
					dense
				>
					<v-col cols="6">
						<Dropdown
							:value="footerContentId"
							:disabled="!userFullPermissions"
							:items="footerOptions"
							custom-sort
							label="Page Footer"
							tooltip-text="
								<p>Select a Cassie Footer that will appear in the footer of this Link page</p>
								</p>Use the quick actions to Create or Edit the selected Footer</p>
							"
							@input="value => $emit('update:footerContentId', value)"
						/>
					</v-col>
					<v-col cols="6">
						<TextButton
							:disabled="!userFullPermissions"
							@click="openCreateHeaderFooter('Footer')"
						>
							<v-icon>
								mdi-plus
							</v-icon>
							Create Footer
						</TextButton>
						<TextButton
							:disabled="!selectedFooter || !userFullPermissions"
							@click="openEditHeaderFooter('Footer', footerContentId)"
						>
							<v-icon>
								mdi-pencil
							</v-icon>
							Edit Selected Footer
						</TextButton>
						<HeaderFooterFormModal
							v-if="showHeaderFooterForm"
							:type-id="headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER"
							:form-to-edit="headerFooterFormData"
							:selected-brand-id="brandId"
							:form-type="selectedFormType"
							@updated="refreshFooters"
							@set-selected-footer:footerId="selectNewFooter"
							@close="() => {
								headerFooterFormData = null
								showHeaderFooterForm = false
							}"
						/>
					</v-col>
				</v-row>
				<slot name="details-card-bottom" />
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Authentication
			</template>
			<template #body>
				<v-row
					dense
				>
					<v-col
						cols="6"
						class="d-flex align-center justify-space-between"
					>
						<Dropdown
							:value="authTypeId"
							:disabled="!userFullPermissions"
							:items="linkAuthTypes.map(({ linkAuthTypeId, linkAuthTypeDescription }) => ({ value: linkAuthTypeId, text: linkAuthTypeDescription}))"
							label="Authentication Type *"
							class="cassie-input-width-xl"
							rules="required"
							@input="value => {
								updateAuthenticationDetails({
										...(authenticationDetails),
										authTypeId: value
								})
								reset()
							}"
						/>
						<v-tooltip
							right
						>
							<template #activator="{ on }">
								<v-icon
									right
									v-on="on"
									@click.stop="authTypeModalOpen = true"
								>
									mdi-information
								</v-icon>
							</template>
							<span>
								Click to expand
							</span>
						</v-tooltip>
					</v-col>
				</v-row>
				<template v-if="authTypeId && authTypeId !== DIRECT_AUTHENTICATION_TYPE">
					<v-row
						v-if="authTypeId === PROVE_EMAIL_AUTHENTICATION_TYPE || authTypeId === PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE"
						dense
					>
						<v-col cols="6">
							<Dropdown
								v-model="selectedProveConfirmationEmailId"
								label="Proof Email *"
								rules="required"
								:items="proveConfirmationEmails.map(({ emailId, emailName }) => ({ value: emailId, text: emailName }))"
								:disabled="!userFullPermissions"
								tooltip-text="The Email that will be sent to the Data Subject as part of the 'Prove Email' or 'Provide & Prove Email' process"
								@change="updateAuthenticationDetails({
									...(authenticationDetails),
									proveConfirmationEmailId: $event
								})"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<TextField
								:value="verificationAreaText"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 2000}"
								label="Authentication Area Text *"
								tooltip-text="The text displayed to the Data Subject at the top of the Authentication page"
								@input="updateAuthenticationDetails({
									...(authenticationDetails),
									verificationAreaText: $event
								})"
							/>
						</v-col>
						<v-col cols="6">
							<TextField
								v-if="authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE || authTypeId === CONFIRM_VALUE_AUTHENTICATION_TYPE && linkType !== linkTypes.publicPortalLink"
								:value="verificationFailedMessage"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 2000}"
								label="Authentication Failed Message *"
								tooltip-text="The text displayed to the Data Subject if the data input leads to Authentication failure"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								verificationFailedMessage: $event
								})"
							/>
							<TextField
								v-else-if="authTypeId === PROVE_EMAIL_AUTHENTICATION_TYPE || authTypeId === PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE"
								:value="standDakAuthFieldFriendlyName"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 50}"
								label="Authentication Field Label *"
								tooltip-text="The label text for the Authentication field that the Data Subject must complete to verify their identity"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								standDakAuthFieldFriendlyName: $event
								})"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								v-if="authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE || authTypeId === CONFIRM_VALUE_AUTHENTICATION_TYPE && linkType !== linkTypes.publicPortalLink"
								:value="standDakAuthField"
								:disabled="!userFullPermissions"
								:items="availableFields"
								custom-sort
								rules="required"
								tooltip-text="The field that the Data Subject must provide the details for when prompted to access the Preference Page"
								:label="authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE ? 'Select the Authentication Provide Value Field *' : 'Select the Authentication Confirm Value Field *'"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								standDakAuthField: $event
								})"
							/>
							<TextField
								v-else-if="authTypeId === PROVE_EMAIL_AUTHENTICATION_TYPE || authTypeId === PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE"
								:value="verificationSentMessage"
								:disabled="!userFullPermissions"
								label="Authentication Success Message *"
								tooltip-text="The text displayed to the Data Subject if this Authentication step has been successful"
								:rules="{required: true, max: 2000}"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								verificationSentMessage: $event
								})"
							/>
						</v-col>
						<v-col cols="6">
							<TextField
								v-if="authTypeId === PROVIDE_EMAIL_AUTHENTICATION_TYPE || authTypeId === CONFIRM_VALUE_AUTHENTICATION_TYPE && linkType !== linkTypes.publicPortalLink"
								:value="standDakAuthFieldFriendlyName"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 50}"
								label="Authentication Field Label *"
								tooltip-text="The label text for the Authentication field that the Data Subject must complete to verify their identity"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								standDakAuthFieldFriendlyName: $event
								})"
							/>
							<TextField
								v-else-if="authTypeId === PROVE_EMAIL_AUTHENTICATION_TYPE || authTypeId === PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE"
								:value="verificationFailedMessage"
								:disabled="!userFullPermissions"
								:rules="{required: true, max: 2000}"
								label="Authentication Failed Message *"
								tooltip-text="The text displayed to the Data Subject if the data input leads to Authentication failure"
								@input="updateAuthenticationDetails({
								...(authenticationDetails),
								verificationFailedMessage: $event
								})"
							/>
						</v-col>
					</v-row>
				</template>
			</template>
		</SectionCard>
		<SectionCard v-if="linkType !== linkTypes.publicPortalLink">
			<template #title>
				Privacy Policy
			</template>
			<template #title-action>
				<Toggle
					class="mr-2"
					:disabled="!userFullPermissions"
					:value.sync="customPrivacyPolicyEnabled"
					label-left
					label="Enabled"
				/>
			</template>
			<template #body>
				<p>
					Enabling this option will allow you to specify Privacy Policy and Version for submissions made using this link depending on the language that it is being displayed to the user in.
				</p>
				<template v-if="customPrivacyPolicyEnabled">
					<div class="cassie-vertical-md">
						<div class="cassie-horizontal-md d-flex flex-row">
							<Dropdown
								v-model="selectedLanguageId"
								label="Language*"
								tooltip-text="Select a Language for this Privacy Policy"
								class="cassie-input-width-md"
								:items="filteredAssignedLanguages"
								:disabled="!userFullPermissions"
							/>
							<Dropdown
								v-model="selectedPrivacyPolicy"
								label="Privacy Policy*"
								tooltip-text="Select the Privacy Policy to be associated with this Preference Page Link"
								:items="brandFilteredActivePrivacyPolicies"
								custom-sort
								:disabled="(!brandId && brandId !== 0) || !userFullPermissions"
								return-object
								class="cassie-input-width-md"
								@input="getLatestVersion()"
							/>
						</div>
						<div class="cassie-horizontal-md d-flex flex-row">
							<Dropdown
								v-if="selectedPrivacyPolicy"
								v-model="selectedPrivacyPolicyVersion"
								:items="versionOptions"
								:disabled="!userFullPermissions"
								return-object
								label="Version*"
								:tooltip-text="
									`<p>Select the version of the Privacy Policy that will be associated with all Preferences submitted from this Preference Page Link</p>
									<p>'Always Use Latest Version' will update the associated Policy version each time this is updated in the Privacy Policies module</p>
									`
								"
								class="cassie-input-width-md"
							/>
							<PrimaryActionButton
								:disabled="!userFullPermissions || !allowAddPrivacyPolicy"
								@click="addPrivacyPolicy"
							>
								Add
							</PrimaryActionButton>
						</div>
						<DataTable
							:headers="tableHeaders"
							:items="customPrivacyPolicies"
							hide-default-footer
						>
							<template #item.action="{ index }">
								<v-icon
									v-if="userFullPermissions"
									small
									@click="removeCustomPrivacyPolicy(index)"
								>
									mdi-trash-can
								</v-icon>
							</template>
						</DataTable>
					</div>
				</template>
			</template>
		</SectionCard>
		<AuthenticationTypesHelperModal
			v-if="authTypeModalOpen"
			:is-preference-page="linkType === linkTypes.preferencePageLink"
			@close="authTypeModalOpen = false"
		/>
	</validation-observer>
</template>
<script>
/* eslint-disable max-lines */
import { mapGetters } from 'vuex'
import SectionCard from '../../../../../shared/components/section-card.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextButton from '../../../../../shared/components/text-button.vue'
import { headersAndFootersTypeIdEnum } from '../../../../../shared/enums/headers-and-footers.js'
import Toggle from '../../../../../shared/components/toggle.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import AuthenticationTypesHelperModal from './authentication-types-helper-modal.vue'
import { getStandakFields } from '../../../../../shared/utils/api/configuration.js'
import {
	CONFIRM_VALUE_AUTHENTICATION_TYPE, DIRECT_AUTHENTICATION_TYPE,	PROVE_EMAIL_AUTHENTICATION_TYPE,	PROVIDE_EMAIL_AUTHENTICATION_TYPE,	PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE
} from './link-authentication-types.js'
import { singleBrand, defaultBrandInSelect, clientMultiBranded, brandOptions } from '../../../../../shared/state/brands.js'
import { LINKS_AREA_FULL_PERMISSIONS, LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { getAssignedLanguages } from '../../../../../shared/utils/api/languages.js'
import { activePrivacyPolicies, privacyPolicies } from '../../../../../shared/state/privacy-policies.js'
import { getFooters } from '../../../../../shared/utils/api/headers-and-footers.js'
import { linkTypes } from './preference-links/link-types.js'
import HeaderFooterFormModal from '../../shared/header-footer-form-modal.vue'
import Message from '../../../../../shared/components/message.vue'
const UNIVERSAL_BRAND = 0
export default {
	components: { Dropdown, TextField, SectionCard, Toggle, PrimaryActionButton, DataTable, HeaderFooterFormModal, TextButton, AuthenticationTypesHelperModal, Message },
	props: {
		authenticationDetails: Object,
		customPrivacyPolicies: Array,
		customDefaultLanguageId: Number,
		linkName: String,
		linkText: String,
		footerContentId: Number,
		brandId: Number,
		linkType: Number,
		editMode: Boolean,
		linkAuthTypes: {
			type: Array,
			default: () => []
		},
		isEnabled: {
			type: Boolean,
			default: () => true
		},
		enableOneClickUnsubscribe: {
			type: Boolean,
			default: () => false
		},
		proveConfirmationEmails: {
			type: Array,
			default: () => []
		},
		cloneMode: Boolean
	},
	setup () {
		return {
			brandOptions,
			linkTypes,
			privacyPolicies,
			activePrivacyPolicies,
			headersAndFootersTypeIdEnum,
			clientMultiBranded,
			singleBrand,
			defaultBrandInSelect
		}
	},
	data () {
		return {
			DIRECT_AUTHENTICATION_TYPE,
			CONFIRM_VALUE_AUTHENTICATION_TYPE,
			PROVE_EMAIL_AUTHENTICATION_TYPE,
			PROVIDE_EMAIL_AUTHENTICATION_TYPE,
			PROVIDE_AND_PROVE_EMAIL_AUTHENTICATION_TYPE,
			availableFields: [],
			customPrivacyPolicyEnabled: false,
			assignedLanguages: [],
			footers: [],
			selectedLanguageId: null,
			selectedPrivacyPolicy: null,
			headerFooterFormData: null,
			showHeaderFooterForm: false,
			selectedFormType: '',
			selectedPrivacyPolicyVersion: null,
			universalBrand: [
				{ text: 'Universal', value: 0	}
			],
			selectedProveConfirmationEmailId: null,
			authTypeModalOpen: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission', 'userHasUniversalBrand']),
		authTypeId () {
			return this.authenticationDetails?.authTypeId
		},
		footerOptions () {
			const options = [
				{ value: null, text: 'None' }
			]
			if (this.footers) {
				options.push(...this.footers.map(({ id, name }) => ({ value: id, text: name })).sort((a, b) => a.text.localeCompare(b.text)))
			}
			return options
		},
		selectedFooter () {
			return this.footerOptions?.find(f => f.value === this.footerContentId)
		},
		standDakAuthField () {
			return this.authenticationDetails?.standDakAuthField
		},
		standDakAuthFieldFriendlyName () {
			return this.authenticationDetails?.standDakAuthFieldFriendlyName
		},
		verificationAreaText () {
			return this.authenticationDetails?.verificationAreaText
		},
		verificationFailedMessage () {
			return this.authenticationDetails?.verificationFailedMessage
		},
		verificationSentMessage () {
			return this.authenticationDetails?.verificationSentMessage
		},
		selectedBrand () {
			if (!this.brandId && this.brandOptions.length === 1) {
				return this.brandOptions[0]
			} else {
				return this.brandId
			}
		},
		userFullPermissions () {
			if (this.brandId === 0 && !this.userHasUniversalBrand) {
				return false
			}
			return this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)
		},
		tableHeaders () {
			return [
				{ value: 'languageName', text: 'Language', width: '15%' },
				{ value: 'privacyPolicyId', text: 'Privacy Policy ID', width: '10%' },
				{ value: 'privacyPolicyName', text: 'Privacy Policy Name', width: '20%' },
				{ value: 'privacyPolicyVersionId', text: 'Cassie Version ID', width: '25%' },
				{ value: 'privacyPolicyExternalVersionRef', text: 'Client Version Identifier', width: '10%' },
				{ value: 'action', text: 'Action', width: '10%' }
			]
		},
		versionOptions () {
			const selectedPrivacyPolicyVersions = privacyPolicies.value.find(({ privacyPolicyId }) => privacyPolicyId === this.selectedPrivacyPolicy.value).versions.map(version => ({
				...version,
				value: version.externalVersionRef,
				text: version.externalVersionRef
			}))
			const useLatestVersion = {
				value: 'Always Use Latest Version',
				text: 'Always Use Latest Version'
			}
			return [
				useLatestVersion,
				...selectedPrivacyPolicyVersions
			]
		},
		brandFilteredActivePrivacyPolicies () {
			let filteredPrivacyPolicies = []

			if (this.brandId === UNIVERSAL_BRAND) {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === UNIVERSAL_BRAND)
			} else {
				filteredPrivacyPolicies = activePrivacyPolicies.value.filter(({ brandId }) => brandId === this.brandId || brandId === UNIVERSAL_BRAND)
			}
			return filteredPrivacyPolicies
		},
		filteredAssignedLanguages () {
			const filteredLanguages = this.assignedLanguages.filter(x => !this.customPrivacyPolicies.some(y => y.languageId === x.value))
			return filteredLanguages
		},
		allowAddPrivacyPolicy () {
			return this.selectedLanguageId !== null && this.selectedPrivacyPolicy !== null && this.selectedPrivacyPolicyVersion !== null
		}
	},
	async created () {
		const { data: availableFields } = await getStandakFields()
		const { data: assignedLanguages } = await getAssignedLanguages()
		this.getFooters()
		const { data: footers } = await getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER)
		this.footers = footers

		this.assignedLanguages = assignedLanguages.map(({ languageID, languageName }) => ({ value: languageID, text: languageName }))
		this.availableFields = availableFields.map(({ columnName }) => ({ value: columnName, text: columnName }))
		if (this.linkType !== linkTypes.publicPortalLink && this.customPrivacyPolicies.length) this.customPrivacyPolicyEnabled = true
		this.selectedProveConfirmationEmailId = this.authenticationDetails?.proveConfirmationEmailId
	},
	methods: {
		updateAuthenticationDetails (authenticationDetails) {
			this.$emit('update:authenticationDetails', authenticationDetails)
		},
		async getFooters () {
			const { data: footers } = await getFooters(headersAndFootersTypeIdEnum.PREFERENCE_PAGE_FOOTER)
			this.footers = footers ?? []
		},
		async refreshFooters () {
			await this.getFooters()
		},
		async selectNewFooter (footerId) {
			this.$emit('update:footerContentId', footerId)
		},
		updatePrivacyPolicies (customPrivacyPolicies) {
			this.$emit('update:customPrivacyPolicies', customPrivacyPolicies)
		},
		getLatestVersion () {
			this.selectedPrivacyPolicyVersion = this.versionOptions[0]
		},
		addPrivacyPolicy () {
			const language = this.assignedLanguages.find(({ value }) => value === this.selectedLanguageId)
			this.updatePrivacyPolicies([
				...this.customPrivacyPolicies,
				{
					languageId: language.value,
					languageName: language.text,
					privacyPolicyId: this.selectedPrivacyPolicy.value,
					privacyPolicyName: this.selectedPrivacyPolicy.text,
					privacyPolicyVersionId: this.selectedPrivacyPolicyVersion.versionId || 'Always Use Latest Version',
					privacyPolicyExternalVersionRef: this.selectedPrivacyPolicyVersion.externalVersionRef
				}
			])
			this.selectedLanguageId = null
			this.selectedPrivacyPolicy = null
			this.selectedPrivacyPolicyVersion = null
		},
		removeCustomPrivacyPolicy (index) {
			const customPrivacyPolicies = [...this.customPrivacyPolicies]
			this.$delete(customPrivacyPolicies, index)
			this.updatePrivacyPolicies(customPrivacyPolicies)
		},
		openCreateHeaderFooter (formType) {
			this.showHeaderFooterForm = false
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
			this.headerFooterFormData = null
		},
		async openEditHeaderFooter (formType, footerId) {
			this.selectedFormType = formType
			const matchingFooter = this.footers.find(({ id }) => id === footerId)
			matchingFooter.brand = matchingFooter.brandId
			this.headerFooterFormData = matchingFooter
			this.showHeaderFooterForm = true
		}
	}
}
</script>
