var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-form',{staticClass:"d-flex flex-column overflow-y-hidden",staticStyle:{"width":"100%"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('ViewLayout',{scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]},proxy:true},{key:"header-caption",fn:function(){return [_vm._v(" "+_vm._s(_vm.pageCaption)+" ")]},proxy:true},{key:"header-after",fn:function(){return [_c('Stepper',{key:_vm.currentStepKey,attrs:{"step":_vm.currentStep,"steps":_vm.steps,"in-progress":!_vm.isEdit},on:{"update:step":function (value) {
					handleSubmit(function () {
						_vm.updateCurrentStep(value)
					})
					_vm.currentStepKey++
				}}})]},proxy:true},{key:"content",fn:function(){return [_vm._t("content")]},proxy:true},{key:"footer",fn:function(){return [_c('PageActionRow',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('SecondaryActionButton',{on:{"click":_vm.backButtonAction}},[_vm._v(" Back to Overview ")]),_c('v-spacer'),_c('div',{staticClass:"cassie-horizontal-md d-flex flex-row"},[(_vm.currentStep && (_vm.currentStep !== _vm.steps.length - 1 || !_vm.userFullPermissions))?_c('SecondaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.previous)}}},[_vm._v(" Previous ")]):_vm._e(),(_vm.currentStep === _vm.steps.length - 2 && _vm.userFullPermissions)?_c('PrimaryActionButton',{attrs:{"disabled":!_vm.linkHasChanged || !_vm.userFullPermissions},on:{"click":function($event){return handleSubmit(_vm.goToLastStep)}}},[_vm._v(" "+_vm._s(!_vm.isEdit ? 'Finish' : 'Save Changes')+" ")]):(_vm.currentStep !== _vm.steps.length - 1)?[(_vm.isEdit)?_c('PrimaryActionButton',{attrs:{"disabled":!_vm.linkHasChanged || !_vm.userFullPermissions},on:{"click":function($event){return handleSubmit(_vm.goToLastStep)}}},[_vm._v(" Save Changes ")]):_vm._e(),_c('PrimaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.next)}}},[_vm._v(" Next ")])]:(_vm.currentStep === _vm.steps.length - 2 && !_vm.userFullPermissions)?_c('PrimaryActionButton',{on:{"click":function($event){return handleSubmit(_vm.next)}}},[_vm._v(" Next ")]):_vm._e()],2)]},proxy:true}],null,true)})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }